import client from '../lib/http-client';
import { buildQuery } from '../lib/helper';

const endpoint = '/surveys';

export default {
  // Create
  create(params, cb, errorCb) {
    const url = endpoint;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get
  get(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Get surveys
  getSurveys(params, cb, errorCb) {
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    const query = buildQuery(params);
    const url = `${endpoint}?${query}`;
    client.get(url)
      .then(responseHandler)
      .catch(errorHandler);
  },

  // Update surveys
  update(id, params, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.put(url, params)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Delete surveys
  delete(id, cb, errorCb) {
    const url = `${endpoint}/${id}`;
    client.delete(url)
      .then((response) => {
        if (cb) cb(response.data);
      })
      .catch((e) => {
        if (errorCb) errorCb(e);
      });
  },

  // Create
  submit(params, cb, errorCb) {
    const url = `${endpoint}/submit`;
    const responseHandler = (response) => {
      if (cb) cb(response.data);
    };
    const errorHandler = (e) => {
      if (errorCb) errorCb(e);
    };
    client.post(url, params)
      .then(responseHandler)
      .catch(errorHandler);
  },
};

<template>
	<div class="page-event-question ml-12 mt-6">
		<div class="vx-col mb-base pr-8">
			<vs-select
				name="list-survey-result"
				class="xs:w-1/1 sm:w-1/1 lg:w-2/3 mb-12"
				:label="$t('Survey')"
				v-model="dataSurvey"
				@change="viewSurveyResult"
				>
				<vs-select-item v-for="(item,index) in surveys"  :key="index" :value="item.id" :text="item.name" />
			</vs-select>
			<div class="wrapper-survey-result xs:w-1/1 sm:w-1/1 lg:w-3/4 mb-12" v-if="!isFetchingSurvey">
				<vs-list v-for="(option,index) in surveyById.options"  :key="index" class="center">
						<span class="index-option">{{ index+1 }}.</span>
					<vs-list-item :title="option.name" class="title-list-option ml-5">
						<span style="font-size: 23px!important">{{ option.__meta__.total_respondent }} {{$t('Vote')}}</span>
					<vs-button
						:class="{ greenPercent: getPercentage(option.__meta__.total_respondent) <= 30,
						yellowPercent: getPercentage(option.__meta__.total_respondent) > 30 && getPercentage(option.__meta__.total_respondent) < 50,
						redPercent: getPercentage(option.__meta__.total_respondent) >= 50,
						}" class="ml-6 percentage-btn" color="danger">{{getPercentage(option.__meta__.total_respondent)}}%</vs-button>
					</vs-list-item>
				</vs-list>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import surveysApi from '@/api/survey';

export default {
  name: 'EventQuestion',
  components: {
  },
  data() {
    return {
      event: null,
      surveys: [],
      isFetching: false,
      orderBy: 'created_at',
      sortBy: 'asc',
      surveyById: '',
      dataSurvey: '',
      selected: [],
      selectedItem: null,
      totalVotePerOption: '',
      totalRespondent: '',
      isFetchingSurvey: false,
      totalVoteAllOption: '',
    };
  },
  sockets: {
    connect() {
      this.initSocket();
    },
    survey_create(payload) {
      this.surveys.push(payload);
    },
    survey_update(payload) {
      if (this.surveyById.id === payload.id) {
        Object.assign(this.surveyById, payload);
      }
    },
    survey_delete(payload) {
      const index = this.surveys.findIndex(curr => curr.id === payload);
      if (index !== -1) {
        this.surveys.splice(index, 1);
      }
    },
  },
  methods: {
    viewSurveyResult(survey) {
      this.fetchSurveyById(survey);
    },
    initSocket() {
      const payload = {
        room_id: this.room,
      };
      // Join Room
      this.$socket.client.emit('join', payload);
    },
    leaveSocketRoom() {
      this.$socket.client.emit('leave', this.room);
    },
    fetch() {
      this.$vs.loading();
      this.isFetching = true;
      const params = {
        page: this.page,
        order_by: this.orderBy,
        sort_by: this.sortBy,
        event_slug: this.eventSlug,
      };
      const callback = (response) => {
        const surveys = response.data;
        this.surveys = surveys;
        this.dataSurvey = surveys[0].id;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetching = false;
        this.$vs.loading.close();
      };
      surveysApi.getSurveys(params, callback, errorCallback);
    },
    fetchSurveyById(survey) {
      this.$vs.loading();
      this.isFetchingSurvey = true;
      const callback = (response) => {
        const surveys = response.data;
        this.surveyById = surveys;
        this.totalVoteAllOption = this.surveyById.__meta__.total_participant;
        this.isFetchingSurvey = false;
        this.$vs.loading.close();
      };
      const errorCallback = () => {
        this.isFetchingSurvey = false;
        this.$vs.loading.close();
      };
      surveysApi.get(survey, callback, errorCallback);
    },
    getPercentage(countVote) {
      const resultPercentage = 0;
      if (countVote !== 0) {
        const vote = countVote / this.totalVoteAllOption;
        const total = vote * 100;
        const decimalSurvey = Math.round((total + Number.EPSILON) * 100) / 100;
        return decimalSurvey;
      }
      return resultPercentage;
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    eventSlug() {
      return this.$route.params.eventSlug;
    },
    room() {
      const room = `event-${this.eventSlug}`;
      return room;
    },
  },
  watch: {
    eventSlug() {
      if (this.eventSlug) this.initSocket();
    },
  },
  created() {
    this.fetch();
    this.initSocket();
  },
  beforeDestroy() {
    this.leaveSocketRoom();
  },
  destroyed() {
  },
};
</script>
<style>
.wrapper-survey-result{
	margin-top: 8em;
}
.center {
  border-bottom: 1px solid #BCB9B9;
  padding: 10px;
}
.percentage-btn {
	min-width: 100px;
	border-radius: 2em!important;
}
.title-list-option .vs-list--title {
	font-weight: 300;
	font-size: 32px!important
}
.index-option{
	position: absolute;
	padding-top: 15px;
	top: 0;
	left:0;
	font-weight: 300;
	font-size: 32px!important;
}
.redPercent {
	background-color: #D50000!important;
}
.yellowPercent {
	background-color: #D3A612!important;
}
.greenPercent {
	background-color: #5ABF68!important;
}
</style>
